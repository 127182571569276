import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.a`
  display: inline-block;
`;

const FacebookIcon = (props) => {
  if (props.href) {
    return (
      <Wrapper href={props.href} target="_blank" rel="noreferrer noopener">
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M 19.253906 2 C 15.311906 2 13 4.0821719 13 8.8261719 L 13 13 L 8 13 L 8 18 L 13 18 L 13 30 L 18 30 L 18 18 L 22 18 L 23 13 L 18 13 L 18 9.671875 C 18 7.884875 18.582766 7 20.259766 7 L 23 7 L 23 2.2050781 C 22.526 2.1410781 21.144906 2 19.253906 2 z" />
        </svg>
      </Wrapper>
    );
  } else {
    return null;
  }
};

FacebookIcon.defaultProps = {
  href: null,
};

FacebookIcon.propTypes = {
  href: PropTypes.string,
};

export default FacebookIcon;
