import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  background-color: #060038;
  height: 57px;
`;

const Content = styled.div`
  align-self: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #ffffff;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  align-self: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #ffffff;
`;

const CopyrightNotice = (props) => (
  <Wrapper>
    <Content>
      <span>
        © {new Date().getFullYear()} by{" "}
        <StyledLink to="/">{props.companyName}</StyledLink>. todos os direitos
        reservados
      </span>
    </Content>
  </Wrapper>
);

CopyrightNotice.propTypes = {
  companyName: PropTypes.string,
};

export default CopyrightNotice;
