import styled from "styled-components";

export const Wrapper = styled.div`
  height: 600px;
  max-height: 600px;
  /* margin-top: 120px; */

  @media (max-width: 768px) {
    height: 398px;
    max-height: 398px;
    /* margin-top: 60px; */
  }
`;

export const Container = styled.aside`
  position: relative;
  margin-top: 175px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 67px;
  }
`;

export const FloatingSideLabel = styled.h2`
  position: absolute;

  /* 210px is the height of the floating label with the white line */
  /* having top set to this value, places it in the same horizontal level as the parent container */
  /* Making it easier to add margin to both of them in the same time */
  top: 210px;

  left: 0;
  color: white;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  transform-origin: top left;
  transform: rotate(-90deg);
  margin-left: -81px;

  /* Draw line before the label */

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    font-weight: bold;
    margin-right: 40px;
    width: 100px;
    color: white;
  }
`;

export const Content = styled.div`
  max-width: 445px;
`;

export const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

  margin-top: 24px;
  margin-bottom: 59px;

  @media (max-width: 768px) {
    margin-bottom: 41px;
  }
`;
