import { HeaderContent, HeaderWrapper } from "./styles";
import MobileNavBar from "@components/mobile-navigation-bar";
import NavBar from "@components/navigation-bar";
import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Header = () => {
  const breakpoints = useBreakpoint();
  const isReady = Object.prototype.hasOwnProperty.call(breakpoints, "mobile");

  return (
    <HeaderWrapper>
      <HeaderContent>
        {isReady && (breakpoints.mobile ? <MobileNavBar /> : <NavBar />)}
      </HeaderContent>
    </HeaderWrapper>
  );
};

export default Header;
