import * as React from "react";
import { StyledLink, Text } from "./styles";
import ArrowToggle from "@components/icons/arrow-toggle";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const ButtonToggler = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.onClick();
    }
  };

  return (
    <StyledLink onKeyDown={handleKeyDown} onClick={props.onClick} tabIndex={0}>
      <Text>{props.text}</Text>
      <ArrowToggle
        color={props.theme.variant === "light" ? "white" : "#060038"}
        toggleUp={props.isOpen}
      />
    </StyledLink>
  );
};

ButtonToggler.defaultProps = {
  isOpen: false,
  onClick: () => {},
  text: "projetos",
};

ButtonToggler.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  theme: PropTypes.shape({
    variant: PropTypes.string,
  }),
};

export default withTheme(ButtonToggler);
