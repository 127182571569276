import { graphql, useStaticQuery } from "gatsby";

export const useProjectCollection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { fields: { folder: { eq: "projetos" } } }) {
          edges {
            node {
              id
              slug
              frontmatter {
                title
                description
                href
                linkLabel
                linkToDisplay
                heroImage
              }
            }
          }
        }
      }
    `
  );

  const getProjectsFrontmatter = () => {
    return data?.allMdx?.edges.map((edge) => {
      const { frontmatter, id, slug } = edge?.node;

      return {
        id: id,
        title: frontmatter.title,
        slug: `/projetos/${slug}/`,
        heroImage: frontmatter.heroImage,
      };
    });
  };

  const getTopProjects = (limit = 6) => {
    const projectsPages = getProjectsFrontmatter()
      .slice(0, limit)
      .map((project) => ({
        title: project.title,
        href: project.slug,
        isBold: false,
        heroImage: project.heroImage,
      }));

    return [...projectsPages];
  };

  return {
    getProjectsFrontmatter,
    getTopProjects,
  };
};
