import BackgroundImage from "gatsby-background-image";
import CopyrightNotice from "@components/copyright-notice";
import DesktopView from "./desktop-view";
import LayoutCenterColumn from "@components/layout-center-column";
import MobileView from "./mobile-view";
import React from "react";
import styled from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useImage } from "@queries/use-image";

const StyledLayoutCenterColumn = styled(LayoutCenterColumn)`
  background-color: #060038;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 57px;
  right: 0;
  z-index: -2;
  overflow: hidden;
  margin: 0 auto;

  * {
    height: 100%;
  }
`;

const Footer = () => {
  const { getFluidImageNameAndExtension } = useImage();
  const breakpoints = useBreakpoint();

  const bgFluid = getFluidImageNameAndExtension("footer-mesh", "png", true);
  const bgFluidImageStack = [
    `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))`,
    bgFluid,
  ];

  return (
    <footer style={{ position: "relative" }}>
      <LayoutCenterColumn>
        {breakpoints.mobile ? <MobileView /> : <DesktopView />}
      </LayoutCenterColumn>

      <StyledLayoutCenterColumn>
        <CopyrightNotice companyName="treetree2" />
      </StyledLayoutCenterColumn>
      {breakpoints.mobile ? null : (
        <Wrapper>
          <div>
            {" "}
            <BackgroundImage
              style={{ width: "100%", height: "100%" }}
              fadeIn={false}
              fluid={bgFluidImageStack}
            />
          </div>
        </Wrapper>
      )}
    </footer>
  );
};

export default Footer;
