import {
  Container,
  Content,
  FloatingSideLabel,
  Subtitle,
  Title,
  Wrapper,
} from "./styles";
import BackgroundImage from "gatsby-background-image";
import LayoutCenterColumn from "@components/layout-center-column";
import React from "react";
import SubscribeForm from "@components/subscribe-form/subscribe-form-component";
import { useImage } from "@queries/use-image";

const Newsletter = () => {
  const { getFluidImageNameAndExtension } = useImage();

  return (
    <Wrapper>
      <BackgroundImage
        style={{ width: "100%", height: "100%" }}
        fadeIn={false}
        fluid={getFluidImageNameAndExtension("newsletter-treetree@3x", "jpg")}
      >
        <LayoutCenterColumn>
          <Container>
            <FloatingSideLabel>newsletter</FloatingSideLabel>
            <Content>
              <Title>Mantém-te ligado!</Title>
              <Subtitle>
                Subscreve a nossa newsletter e podes receber todas as novidades
                da treebo, diretamente no teu email.
              </Subtitle>
              <SubscribeForm />
            </Content>
          </Container>
        </LayoutCenterColumn>
      </BackgroundImage>
    </Wrapper>
  );
};

export default Newsletter;
