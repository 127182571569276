import styled from "styled-components";

// unfortunatly, CookieConsent is not styled component friendly

export const Wrapper = styled.div`
  .container-cookie-consent {
    border-radius: 4px !important;
    bottom: 42px !important;
    left: 16px !important;
    right: 16px !important;
    max-width: 1110px !important;
    width: unset !important;
    margin: 0 auto !important;
    align-items: center !important;
  }

  .button-cookie-consent {
    background-color: #ffffff !important;
    border-radius: 10px !important;
    padding: 11px 24px !important;
    font-size: 14px !important;
  }

  @media (max-width: 768px) {
    .button-wrapper-cookie-consent {
      width: 100% !important;
    }

    .button-cookie-consent {
      margin: 15px !important;
      width: calc(100% - 30px) !important;
    }
  }
`;
