import styled from "styled-components";

export const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  z-index: 100;
`;

export const HeaderContent = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
`;
