import {
  LinkButtons,
  MenuContent,
  StyledLink,
  StyledList,
  Wrapper,
} from "./styles";
import Button from "@components/button";
import { Link } from "gatsby";
import React from "react";

const links = [
  { title: "sobre nós", href: "/about/" },
  { title: "imprensa", href: "/imprensa/" },
  { title: "novidades", href: "/novidades/" },
  { title: "inspiração", href: "/inspiracao/" },
  { title: "contactos", href: "/contactos/" },
  { title: "voluntariado", href: "/voluntariar/" },
  { title: "apoio/donativos", href: "/apoiar/" },
];

const ListOfLinks = () => (
  <StyledList>
    {links.map((link) => {
      const { title, href } = link;
      return (
        <StyledLink key={href} to={href}>
          {title}
        </StyledLink>
      );
    })}
  </StyledList>
);

const index = () => {
  return (
    <Wrapper>
      <MenuContent>
        <ListOfLinks />
        <LinkButtons>
          <Link to="/voluntariar/">
            <Button size="medium-large" color="white" bg="none" border="white">
              voluntariar
            </Button>
          </Link>
          <Link to="/apoiar/">
            <Button size="medium-large" color="white" bg="orange" border="none">
              apoiar
            </Button>
          </Link>
        </LinkButtons>
      </MenuContent>
    </Wrapper>
  );
};

index.propTypes = {};

export default index;
