import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import React from "react";

const Container = styled.div`
  display: inline-block;
  width: 36px;
  height: 40px;
  text-align: center;
`;

const StyledButton = styled.button`
  height: 40px; // height of the hit box for the finger
  outline: none;
  padding: 0;
  border: 0;
  background: transparent;
  vertical-align: middle;
`;

const BurgerIcon = styled.span`
  display: block;
  position: relative;

  &,
  &::before {
    width: 36px;
    height: 2px;
    background: ${(props) => {
      if (props.isOpen) {
        return "white";
      }

      return props.isLigthVariant ? "white" : "#060038";
    }};
    transition: transform 150ms ease-in-out, opacity 200ms linear;
  }

  &::after {
    width: 25px;
    height: 2px;
    background: ${(props) => {
      if (props.isOpen) {
        return "white";
      }

      return props.isLigthVariant ? "white" : "#060038";
    }};
    transition: transform 350ms ease-in-out, opacity 200ms linear;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
  }

  &::before {
    bottom: 8px;
  }

  &::after {
    top: 8px;
  }

  ${(props) => {
    if (props.isOpen) {
      return css`
        transform: rotate(45deg); // MIDDLE STICK
        width: 22px;

        &::before {
          transform: rotate(90deg) translate(8px); // TOP STICK
        }

        &::after {
          display: none; // BOTTOM STICK
        }
      `;
    }
  }}
`;

const AnimatedMenu = ({ isOpen, handleClick, isLigthVariant }) => {
  return (
    <Container>
      <StyledButton aria-label="menu" onClick={handleClick}>
        <BurgerIcon isOpen={isOpen} isLigthVariant={isLigthVariant} />
      </StyledButton>
    </Container>
  );
};

AnimatedMenu.defaultProps = {
  isOpen: false,
  handleClick: () => {},
};

AnimatedMenu.propTypes = {
  isOpen: PropTypes.bool,
  handleClick: PropTypes.func,
  isLigthVariant: PropTypes.bool,
};

export default AnimatedMenu;
