import * as cn from "classnames";
import CookieBanner from "@components/cookie-banner/cookie-banner-component";
import Footer from "@components/footer";
import Header from "@components/header";
import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider } from "styled-components";
import styles from "./layout.module.scss";

const Main = (props) => (
  <main className={props.className}>{props.children}</main>
);

const Layout = (props) => (
  <div className={styles.layoutWrapper}>
    <CookieBanner />
    {/* theme should be defined outside this file and imported. */}
    <ThemeProvider
      theme={{ variant: props.isHeroAvailable ? "light" : "dark" }}
    >
      <Header />
    </ThemeProvider>
    <Main className={cn({ [styles.extraPadding]: !props.isHeroAvailable })}>
      {props.children}
    </Main>
    <Footer />
  </div>
);

Main.defaultProps = {
  style: {},
};

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Layout.defaultProps = {
  isHeroAvailable: false,
};

Layout.propTypes = {
  children: PropTypes.node,
  isHeroAvailable: PropTypes.bool,
};

export default Layout;
