import * as React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import styled from "styled-components";

export const RightShifted = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  height: 300px;
  width: 100%;
  z-index: -2;
`;

export const StyledBackground = styled(Img)`
  border-radius: 5px;
  max-width: calc(1440px / 2);
  width: 100%;
  height: 300px;
`;

export const SlideoutContainer = styled.div`
  z-index: 2;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: 300px;
  background-color: white;
  position: relative;
  box-shadow: 0px 16px 29px 1px rgba(38, 38, 38, 0.75);
`;

export const Trinangle = styled.div`
  position: absolute;
  top: -8px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #ffffff transparent;
`;

export const InnerWraper = styled.div`
  display: flex;
  flex-direction: row;
`;

// PROJECT SECTION

export const ProjectsSection = styled.div`
  flex: 1;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
  color: #060038;

  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid #e43f5a;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > ${StyledLink} + ${StyledLink} {
    margin-top: 6px;
  }
`;

export const ProjectsList = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: row;

  & > ${FlexColumn} + ${FlexColumn} {
    margin-left: 58px;
  }
`;

export const Header = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
  color: #060038;

  &:hover {
    border-bottom: 2px solid #e43f5a;
  }
`;

export const columnWithLinks = (data, setPreview) => {
  return (
    <FlexColumn>
      {data.map((item) => {
        return (
          <div key={item.href}>
            <StyledLink
              to={item.href}
              onMouseEnter={() => setPreview(item.heroImage)}
              onMouseLeave={() => setPreview(null)}
            >
              {item.title}
            </StyledLink>
          </div>
        );
      })}
    </FlexColumn>
  );
};

// IMAGE PREVIEW SECTION

export const PreviewSection = styled.div`
  flex: 1;
  max-width: 445px;
  width: 100%;
  max-height: 210px;
  height: 100%;
  align-self: center;
`;

export const StyledImg = styled(Img)`
  border-radius: 5px;
  width: 100%;
  height: 210px;
`;
