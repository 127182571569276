import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const toPixel = (value) => `${value}px`;

const EmptySpace = ({ desktop, mobile }) => {
  const [config, setConfig] = useState({});
  const breakpoints = useBreakpoint();

  useLayoutEffect(() => {
    setConfig(breakpoints.laptop ? { ...desktop } : { ...mobile });
  }, [breakpoints]);

  return (
    <div
      style={{
        display: config.isHidden ? "none" : "block",
        marginTop: toPixel(Math.floor(config.margin / 2)),
        marginBottom: toPixel(Math.floor(config.margin / 2)),
      }}
    ></div>
  );
};

EmptySpace.defaultProps = {
  desktop: {
    margin: 0,
    isHidden: false,
  },
  mobile: {
    margin: 0,
    isHidden: false,
  },
};

EmptySpace.propTypes = {
  desktop: PropTypes.shape({
    isHidden: PropTypes.bool,
    margin: PropTypes.number,
  }),
  mobile: PropTypes.shape({
    isHidden: PropTypes.bool,
    margin: PropTypes.number,
  }),
};

export default EmptySpace;
