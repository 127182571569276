import {
  Header,
  InnerWraper,
  PreviewSection,
  ProjectsList,
  ProjectsSection,
  RightShifted,
  SlideoutContainer,
  StyledBackground,
  StyledImg,
  Trinangle,
  columnWithLinks,
} from "./styles";
import React, { useEffect, useState } from "react";
import LayoutCenterColumn from "@components/layout-center-column";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { splitIntoChunks } from "@utilities/array";
import { useImage } from "@queries/use-image";
import { useProjectCollection } from "@queries/use-project-collection";

const Slideout = React.forwardRef((props, ref) => {
  const [visible, setVisible] = useState(true);
  const [preview, setPreview] = React.useState(null);
  const { getTopProjects } = useProjectCollection();
  const { getFluidImageName } = useImage();

  const handleScroll = debounce(() => {
    if (window) {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos <= 400) {
        setVisible(true);
      } else {
        if (visible) {
          setVisible(false);
          props.onViewportLeave();
        }
      }
    }
  }, 100);

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [visible, handleScroll]);

  const allProjects = React.useMemo(() => {
    return getTopProjects(8);
  });

  if (!props.isOpen) {
    return null;
  }

  return (
    <SlideoutContainer ref={ref}>
      <LayoutCenterColumn>
        <InnerWraper>
          <ProjectsSection>
            <Header to="/projetos/">Ver todos os projetos</Header>
            <ProjectsList>
              {splitIntoChunks(allProjects, 4).map((set) =>
                columnWithLinks(set, setPreview)
              )}
            </ProjectsList>
          </ProjectsSection>

          <PreviewSection>
            {preview && <StyledImg fluid={getFluidImageName(preview)} />}
          </PreviewSection>
        </InnerWraper>
      </LayoutCenterColumn>
      <Trinangle />
      <RightShifted>
        <div>
          {" "}
          <StyledBackground
            fluid={getFluidImageName("white-orange-mesh-scaled")}
          />
        </div>
      </RightShifted>
    </SlideoutContainer>
  );
});

Slideout.displayName = "Slideout";

Slideout.defaultProps = {
  isOpen: false,
  onViewportLeave: () => {},
};

Slideout.propTypes = {
  isOpen: PropTypes.bool,
  onViewportLeave: PropTypes.func,
};

export default Slideout;
