import PropTypes from "prop-types";
import React from "react";
import styles from "./full-bleed.module.scss";

const FullBleed = (props) => (
  <div className={styles.fullBleedWrapper}>{props.children}</div>
);

FullBleed.propTypes = {
  children: PropTypes.node,
};

export default FullBleed;
