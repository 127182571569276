import {
  ActionsWrapper,
  LinksWrapper,
  Nav,
  RightSide,
  StyledNavLink,
} from "./styles";
import React, { useCallback, useRef } from "react";
import Button from "@components/button";
import ButtonToggler from "@components/button-toggler/component";
import LayoutCenterColumn from "@components/layout-center-column";
import Logo from "@components/logo";
import PropTypes from "prop-types";
import SlideoutMenu from "@components/slideout-menu/component";
import { navigate } from "gatsby";
import useOnClickOutside from "@utilities/use-on-click-outside";
import { withTheme } from "styled-components";

const NavigationBar = (props) => {
  const slideOutRef = useRef();
  const isDarkVariant = props.theme?.variant === "dark";
  const [slideoutVisible, setSlideoutVisibility] = React.useState(false);

  const hideSlideout = useCallback(() => {
    setSlideoutVisibility(false);
  }, []);

  useOnClickOutside(slideOutRef, hideSlideout);

  return (
    <React.Fragment>
      <LayoutCenterColumn>
        <Nav>
          <Logo />

          <RightSide>
            <LinksWrapper>
              <li>
                <StyledNavLink to="/about/">sobre</StyledNavLink>
              </li>
              <li>
                <ButtonToggler
                  onClick={() => setSlideoutVisibility(!slideoutVisible)}
                  text="projetos"
                  isOpen={slideoutVisible}
                />
              </li>
              <li>
                <StyledNavLink to="/novidades/">novidades</StyledNavLink>
              </li>
              <li>
                <StyledNavLink to="/contactos/">contactos</StyledNavLink>
              </li>
            </LinksWrapper>

            <ActionsWrapper>
              <li>
                <Button
                  onClick={() => navigate("/voluntariar/")}
                  size="medium"
                  color={isDarkVariant ? "black" : "white"}
                  bg="none"
                  border={isDarkVariant ? "blue" : "white"}
                >
                  voluntariar
                </Button>
              </li>
              <li>
                <Button
                  onClick={() => navigate("/apoiar/")}
                  size="medium"
                  color="white"
                  bg="orange"
                  border="none"
                >
                  apoiar
                </Button>
              </li>
            </ActionsWrapper>
          </RightSide>
        </Nav>
      </LayoutCenterColumn>

      <SlideoutMenu
        ref={slideOutRef}
        isOpen={slideoutVisible}
        onViewportLeave={() => setSlideoutVisibility(false)}
      />
    </React.Fragment>
  );
};

NavigationBar.propTypes = {
  theme: PropTypes.shape({
    variant: PropTypes.string,
  }),
};

export default withTheme(NavigationBar);
