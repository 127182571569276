import { Link } from "gatsby";
import styled from "styled-components";

export const EmailInputField = styled.input.attrs(() => ({
  name: "EMAIL",
  type: "email",
  placeholder: "o teu email",
}))`
  border: 0;
  flex: 1;
  font-size: 15px;
  background: transparent;
  padding-left: 30px;

  &::placeholder {
    color: var(--black);
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: black;
    font-size: 14px;
    opacity: 1;
  }
`;

export const SubmitButton = styled.button.attrs(() => ({
  type: "submit",
}))`
  cursor: pointer;
  border: 0;
  background-color: #060038;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 86px;
  height: 100%;
  line-height: 3.3;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #0598f9;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const SubmitEmailContainer = styled.div`
  display: flex;
  border-radius: 5px;
  height: 39px;
  background-color: white;

  &:focus-within {
    box-shadow: 0 0 0 2px #0598f9;
  }
`;

export const AgreementDeclaration = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

export const ErrorMessage = styled.span`
  color: #060038;
  display: inline-block;
  margin-top: 10px;
  max-width: 340px;
  font-size: 12px;
  margin-left: 2px;
`;

export const CustomCheckbox = styled.span.attrs(() => ({
  role: "checkbox",
  tabindex: "0",
}))`
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: white;
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  margin-right: 16px;
  border-radius: 5px;
  outline: none;
`;

export const AgreementContainer = styled.div`
  margin-top: 20px;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  label {
    display: flex;
    align-items: center;
    color: red;
  }

  input[type="checkbox"]:checked + label ${CustomCheckbox} {
    background-color: #060038;
  }

  input[type="checkbox"]:checked + label ${CustomCheckbox}::after {
    content: "\\2713";
    position: absolute;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
`;

export const StyledLink = styled(Link)`
  color: white;
  white-space: nowrap;

  &:hover {
    color: #060038;
  }
`;
