import FacebookIcon from "@components/icons/facebook-component";
import InstagramIcon from "@components/icons/instagram-component";
import { Link } from "gatsby";
import Logo from "@components/logo";
import React from "react";
import VisitCard from "./visit-card";
import styled from "styled-components";

const BoldLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.58;
  letter-spacing: normal;
  color: #060038;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

// DATA

const listMainPages = [
  { title: "sobre nós", href: "/about/", isBold: true },
  { title: "imprensa", href: "/imprensa/", isBold: true },
  { title: "novidades", href: "/novidades/", isBold: true },
  { title: "inspiração", href: "/inspiracao/", isBold: true },
  { title: "contactos", href: "/contactos/", isBold: true },
  { title: "voluntariado", href: "/voluntariar/", isBold: true },
];

const listGeneralPages = [
  { title: "apoio/donativos", href: "/apoiar/", isBold: true },
  { title: "projetos", href: "/projetos/", isBold: true },
  { title: "perguntas frequentes", href: "/404/", isBold: true },
  { title: "termos e condições", href: "/termos-e-condicoes/", isBold: true },
  { title: "política de privacidade", href: "/privacidade/", isBold: true },
];

const columnWithLinks = (data) => {
  return (
    <FlexColumn>
      {data.map((item) => {
        return (
          <BoldLink key={item.href} to={item.href} isBold={item.isBold}>
            {item.title}
          </BoldLink>
        );
      })}
    </FlexColumn>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr auto;
  grid-column-gap: 20px;
  margin-top: 52px;
  margin-bottom: 52px;
`;

const Bottom = styled.div`
  grid-area: 3 / 1 / 4 / 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`;

const LogoWrapper = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  margin-bottom: 51px;
`;

const SocialIcons = styled.div`
  * + * {
    margin-left: 30px;
  }
`;

const MobileView = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {columnWithLinks(listMainPages)}
      {columnWithLinks(listGeneralPages)}
      <Bottom>
        <VisitCard />
        <SocialIcons>
          <FacebookIcon href={null} />
          <InstagramIcon href={null} />
        </SocialIcons>
      </Bottom>
    </Wrapper>
  );
};

export default MobileView;
