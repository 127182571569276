import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-column-gap: var(--spacing-m);
  grid-template-columns: 1fr calc(min(1110px, calc(100% - 32px))) 1fr;
`;

const Content = styled.div`
  grid-column: 2;
`;

const LayoutCenterColumn = (props) => (
  <Wrapper className={props.className}>
    <Content>{props.children}</Content>
  </Wrapper>
);

LayoutCenterColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default LayoutCenterColumn;
