import { Link } from "gatsby";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-color: #060038;
  padding-left: 15px;
  padding-bottom: 22px;
`;

export const MenuContent = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 141px;
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;

  &[aria-current="page"] {
    border-bottom: #e43f5a solid 1px;
  }
`;

export const LinkButtons = styled.div`
  * + * {
    margin-left: 28px;
  }
`;
