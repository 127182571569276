import * as React from "react";
import CookieConsent from "react-cookie-consent";
import { Wrapper } from "./cookie-banner-component-styles";

const CookieBanner = () => {
  return (
    <Wrapper>
      <CookieConsent
        containerClasses="container-cookie-consent"
        contentClasses="content-cookie-consent"
        buttonWrapperClasses="button-wrapper-cookie-consent"
        buttonClasses="button-cookie-consent"
        declineButtonClasses="decline-button-cookie-consent"
        overlayClasses="overlay-cookie-consent"
        location="bottom"
        buttonText="aceitar"
        cookieName="gatsby-gdpr-google-analytics"
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Este site utiliza cookies para melhorar a funcionalidade e a sua
        experiência de utilização do mesmo. Ao clicares em “Aceitar”, concordas
        com a utilização das cookies.
      </CookieConsent>
    </Wrapper>
  );
};

export default CookieBanner;
