import styled from "styled-components";

export const StyledLink = styled.a`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  white-space: nowrap;
  color: ${(props) => (props.theme.variant === "light" ? "white" : "#060038")};

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #e43f5a;
  }
`;

export const Text = styled.div`
  display: inline-block;
`;
