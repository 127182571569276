import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.a`
  display: inline-block;
  height: auto;
  width: 9px;
  margin-left: 7px;
  margin-right: 7px;

  fill: ${(props) => props.color && props.color};
`;

const ArrowDown = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 330 330">
    <path
      id="XMLID_225_"
      d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
    />
  </svg>
);

const ArrowUp = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 330 330">
    <path
      id="XMLID_224_"
      d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
    />
  </svg>
);

const ArrowToggle = (props) => {
  return (
    <Wrapper color={props.color}>
      {props.toggleUp ? <ArrowUp /> : <ArrowDown />}
    </Wrapper>
  );
};

ArrowToggle.defaultProps = {
  toggleUp: true,
  color: "white",
};

ArrowToggle.propTypes = {
  toggleUp: PropTypes.bool,
  color: PropTypes.string,
};

export default ArrowToggle;
