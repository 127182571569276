import { Link } from "gatsby";
import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Temporary
export const ActionsWrapper = styled.ul`
  display: flex;

  li + li {
    margin-left: 20px;
  }
`;

export const StyledNavLink = styled(Link)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  white-space: nowrap;
  color: ${(props) => (props.theme.variant === "light" ? "white" : "#060038")};

  &:hover {
    border-bottom: 2px solid #e43f5a;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  ${ActionsWrapper} {
    margin-left: 80px;

    @media (max-width: 1024px) {
      margin-left: 40px;
    }

    @media (max-width: 850px) {
      margin-left: 20px;
    }
  }
`;

export const LinksWrapper = styled.ul`
  display: flex;

  li + li {
    margin-left: 40px;

    @media (max-width: 1024px) {
      margin-left: 20px;
    }

    @media (max-width: 850px) {
      margin-left: 10px;
    }
  }
`;
