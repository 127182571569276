import {
  AgreementContainer,
  AgreementDeclaration,
  CustomCheckbox,
  EmailInputField,
  ErrorMessage,
  StyledLink,
  SubmitButton,
  SubmitEmailContainer,
} from "./styles";
import React, { useState } from "react";
import { isValidString } from "@utilities/string";
import { nanoid } from "nanoid";

const RightArrow = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 476.213 476.213"
    xmlSpace="preserve"
    style={{ width: "40%", height: "100%" }}
  >
    <polygon
      fill="white"
      points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 
	345.606,368.713 476.213,238.106 "
    />
  </svg>
);

const SubscribeFormComponent = () => {
  const formRef = React.useRef();
  const inputRef = React.useRef();
  const idRef = React.useRef(nanoid());
  const [isChecked, setCheckbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleCheckbox = () => {
    setCheckbox(!isChecked);
    if (isChecked) {
      setErrorMessage("");
    }
  };

  const handleSubmitButton = (event) => {
    event.preventDefault();

    if (
      !isValidString(inputRef?.current?.value) ||
      !inputRef?.current?.checkValidity()
    ) {
      setErrorMessage("Por favor, introduza um email valido.");
      return undefined;
    }

    if (!isChecked) {
      setErrorMessage(
        "Por favor, leia e aceite os Termos e Condições e a Política de Privacidade."
      );
      return undefined;
    }

    if (formRef?.current?.submit) {
      formRef.current.submit();
    }

    cleanForm();
  };

  const cleanForm = () => {
    if (inputRef?.current) {
      inputRef.current.value = "";
    }

    setCheckbox(false);
    setErrorMessage("");
  };

  return (
    <form
      action="MAILCHIMP URL"
      method="post"
      noValidate
      ref={formRef}
      target="_blank"
    >
      <input type="hidden" name="u" value="MAILCHIMP U" />
      <input type="hidden" name="id" value="MAILCHIMP ID" />

      <SubmitEmailContainer>
        <EmailInputField
          autocapitalize="none"
          autocorrect="off"
          size="5"
          name="EMAIL"
          ref={inputRef}
        />
        <SubmitButton
          onClick={handleSubmitButton}
          aria-label="subscribe to newsletter"
        >
          <RightArrow />
        </SubmitButton>
      </SubmitEmailContainer>

      <AgreementContainer>
        <input
          id={idRef?.current}
          checked={isChecked}
          onChange={toggleCheckbox}
          type="checkbox"
        />

        <label htmlFor={idRef?.current}>
          <CustomCheckbox
            aria-checked={isChecked}
            aria-label="checkbox"
            onChange={toggleCheckbox}
            onKeyDown={(event) => event.key === "Enter" && toggleCheckbox()}
          ></CustomCheckbox>

          <AgreementDeclaration>
            {"Declaro que li e aceito os "}
            {
              <StyledLink to="/termos-e-condicoes/">
                Termos e Condições
              </StyledLink>
            }
            {" e a "}
            {
              <StyledLink to="/privacidade/">
                Política de Privacidade
              </StyledLink>
            }
            .
          </AgreementDeclaration>
        </label>

        {isValidString(errorMessage) && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </AgreementContainer>
    </form>
  );
};

export default SubscribeFormComponent;
