import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styles from "./overlay.module.scss";

const overlayElement =
  typeof document !== `undefined` ? document.getElementById("overlay") : null;
const isAccessible = (element) => element !== null && element !== undefined;

class OverlayPortal extends Component {
  constructor() {
    super();
    this.divElement =
      typeof document !== `undefined` ? document.createElement("div") : null;
  }

  componentDidMount() {
    if (isAccessible(overlayElement)) {
      overlayElement.appendChild(this.divElement);
    }
  }

  componentWillUnmount() {
    if (isAccessible(overlayElement)) {
      overlayElement.removeChild(this.divElement);
    }
  }

  render() {
    if (isAccessible(this.divElement)) {
      return ReactDOM.createPortal(
        <div className={styles.overlayWrapper}>{this.props.children}</div>,
        this.divElement
      );
    } else {
      return null;
    }
  }
}

OverlayPortal.propTypes = {
  children: PropTypes.node,
};

export default OverlayPortal;
