import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const StyledEmail = styled.a`
  text-decoration: none;
  font-size: 14px;
  font-stretch: normal;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const VisitCard = () => {
  return (
    <Wrapper>
      <StyledSpan>contacta-nos em</StyledSpan>
      <StyledEmail href="mailto:aprender@treetree2.org">
        aprender@treetree2.org
      </StyledEmail>
    </Wrapper>
  );
};

export default VisitCard;
