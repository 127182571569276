import React, { useEffect, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import AnimatedMenu from "@components/animated-menu";
import LayoutCenterColumn from "@components/layout-center-column";
import Logo from "@components/logo";
import MobileMenu from "@components/mobile-menu";
import OverlayPortal from "@components/overlay-portal";
import PropTypes from "prop-types";
import styles from "./mobile-navigation-bar.module.scss";
import { withTheme } from "styled-components";

const MobileNavigationBar = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isLigthVariant = props.theme?.variant === "light";

  useEffect(() => {
    if (menuOpen) {
      disableBodyScroll(document.querySelector("body"));
    } else {
      enableBodyScroll(document.querySelector("body"));
    }
  }, [menuOpen]);

  return (
    <LayoutCenterColumn>
      <nav className={styles.wrapper}>
        <Logo isWhite={menuOpen} />
        <AnimatedMenu
          isOpen={menuOpen}
          handleClick={() => setMenuOpen(!menuOpen)}
          isLigthVariant={isLigthVariant}
        />
        {menuOpen && (
          <OverlayPortal>
            <MobileMenu />
          </OverlayPortal>
        )}
      </nav>
    </LayoutCenterColumn>
  );
};

MobileNavigationBar.propTypes = {
  theme: PropTypes.shape({
    variant: PropTypes.string,
  }),
};

export default withTheme(MobileNavigationBar);
