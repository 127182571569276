import FacebookIcon from "@components/icons/facebook-component";
import InstagramIcon from "@components/icons/instagram-component";
import { Link } from "gatsby";
import Logo from "@components/logo";
import React from "react";
import VisitCard from "./visit-card";
import styled from "styled-components";
import { useProjectCollection } from "@queries/use-project-collection";

const BoldLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: ${(props) => props.isBold && "bold"};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;

  margin-top: 150px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    background-color: green;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > ${BoldLink} + ${BoldLink} {
    margin-top: 10px;
  }
`;

const SocialIcons = styled.div`
  * + * {
    margin-left: 30px;
  }
`;

// DATA

const listMainPages = [
  { title: "sobre nós", href: "/about/", isBold: true },
  { title: "imprensa", href: "/imprensa/", isBold: true },
  { title: "novidades", href: "/novidades/", isBold: true },
  { title: "inspiração", href: "/inspiracao/", isBold: true },
  { title: "contactos", href: "/contactos/", isBold: true },
  { title: "voluntariado", href: "/voluntariar/", isBold: true },
  { title: "apoio/donativos", href: "/apoiar/", isBold: true },
];

const listGeneralPages = [
  { title: "termos e condições", href: "/termos-e-condicoes/", isBold: true },
  { title: "política de privacidade", href: "/privacidade/", isBold: true },
];

const columnWithLinks = (data) => {
  return (
    <FlexColumn>
      {data.map((item) => {
        return (
          <BoldLink key={item.href} to={item.href} isBold={item.isBold}>
            {item.title}
          </BoldLink>
        );
      })}
    </FlexColumn>
  );
};

const DesktopView = () => {
  const { getTopProjects } = useProjectCollection();

  return (
    <Wrapper>
      <FlexColumn>
        <Logo />
        <div style={{ marginTop: 65 }}></div>
        <VisitCard />
        <div style={{ marginTop: 50 }}></div>
        <SocialIcons>
          <FacebookIcon href={null} />
          <InstagramIcon href={null} />
        </SocialIcons>
      </FlexColumn>

      {columnWithLinks(listMainPages)}

      {columnWithLinks([
        { title: "projetos", href: "/projetos/", isBold: true },
        ...getTopProjects(6),
      ])}

      {columnWithLinks(listGeneralPages)}
    </Wrapper>
  );
};

export default DesktopView;
